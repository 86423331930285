<template>
   <div>
        <div class="match-detail__info-body">
            <!--  && !isSkeletonLoading.event -->
            <div v-if="isShowEvent">
                <div class="match-detail__info-header" v-show="matchDetailEventList.length>0">{{$t('MATCH_EVENT')}}</div>
                <div  v-for="(o, index) in matchDetailEventList" :key="index" >
                    <div class="match-detail__info-timeline 1"  v-if="o.isHome && o.eventTypeKey !='HT_LABEL' && o.eventTypeKey !='FT_LABEL' && o.eventTypeKey !='PEN_LABEL' && o.eventTypeKey != 'AET_LABEL'">
                        <div class="match-detail__info-timeline__left">
                            <div class="display-flex-fe">
                                <div class="match-detail__info-timeline__left-name">
                                    <div class="match-detail__info-timeline__left-name__1">{{o.playerName}}</div>
                                    <div class="match-detail__info-timeline__left-name__2">{{o.assistName}}</div>
                                </div>
                                <div class="match__event-type" :class="o.eventTypeKey"></div>
                            </div>
                             <div v-if ="o.videoUrl == null || o.videoUrl == '' "></div>
                            <div class="match-detail__thumbnail-section match-detail__info-replay" style="padding:0;"  @click="handleVideo(o)" v-else>
                                <div class="match-detail__thumbnail-section__overlay flex-col">
                                    <img src="../../../../../static/images/thumbnail_video_preview.png">
                                    <!-- <video class="match-detail__thumbnail-iframe" controls v-if="o.isAudioType" :src="`${o.videoUrl}#t=0.1`"></video>
                                    <iframe class="match-detail__thumbnail-iframe" v-else  :src="o.videoUrl"  ></iframe>                                  -->
                                </div>
                            </div>
                        </div>
                        <div class="match-detail__info-timeline__middle">
                                <div v-if="o.eventTypeKey == 'penaltyPenMiss' || o.eventTypeKey == 'penaltyGoal' ">                              
                                 </div>
                                 <div v-else>
                                    <div v-if="o.extraMin == null" class="match-detail__info-timeline__middle-time">{{o.eventMinute}}"</div>
                                    <div v-else class="match-detail__info-timeline__middle-time">{{o.eventMinute}}<span> + {{o.extraMin}}"</span></div>
                                </div>
                            <div class="match-detail__info-timeline__middle-score"  v-show="o.result">   
                                <div>{{o.homeScore}}</div>
                                <div>-</div>
                                <div>{{o.awayScore}}</div>
                            </div>
                        </div>
                        <div class="match-detail__info-timeline__right"></div>
                    </div>
                    <div class="match-detail__info-timeline 2"  v-if="!o.isHome && o.eventTypeKey !='HT_LABEL' && o.eventTypeKey !='FT_LABEL' && o.eventTypeKey !='PEN_LABEL' &&  o.eventTypeKey !='AET_LABEL' ">
                        <div class="match-detail__info-timeline__left">                      
                        </div>
                        <div class="match-detail__info-timeline__middle" >
                                <div v-if="o.eventTypeKey =='penaltyPenMiss' || o.eventTypeKey =='penaltyGoal' ">                               
                                 </div>
                                 <div v-else>
                                    <div v-if="o.extraMin == null" class="match-detail__info-timeline__middle-time">{{o.eventMinute}}"</div>
                                    <div v-else class="match-detail__info-timeline__middle-time">{{o.eventMinute}}<span> + {{o.extraMin}}"</span></div>
                                </div>

                            <div class="match-detail__info-timeline__middle-score" v-show = "o.result">   
                                <div>{{o.homeScore}}</div>
                                <div>-</div>
                                <div>{{o.awayScore}}</div>
                            </div>                   
                        </div>
                        <div class="match-detail__info-timeline__right">
                            <div class="display-flex-fs">
                                <div class="match__event-type" :class="o.eventTypeKey"></div>
                                <div class="match-detail__info-timeline__right-name">
                                    <div class="match-detail__info-timeline__left-name__1">{{o.playerName}}</div>
                                    <div class="match-detail__info-timeline__left-name__2">{{o.assistName}}</div>
                                </div>                           
                            </div>
                            <div v-if ="o.videoUrl ==null || o.videoUrl == '' "></div>
                           <div class="match-detail__thumbnail-section match-detail__info-replay" style="padding:0" @click="handleVideo(o)" v-else>
                                  <div class="match-detail__thumbnail-section__overlay flex-col" @click="handleVideo(o)">
                                    <img src="../../../../../static/images/thumbnail_video_preview.png">
                                      <!-- <video class="match-detail__thumbnail-iframe" controls v-if="o.isAudioType" :src="`${o.videoUrl}#t=0.1`" ></video>
                                    <iframe class="match-detail__thumbnail-iframe" v-else  :src="o.videoUrl"  ></iframe> -->
                                  </div>
                                <!-- <img src="../../../../../static/images/match_detail_pre_video.png"> -->
                                <div class="match-detail__thumbnail-section__overlay flex-col">
                                    <!-- <div class="match-detail__thumbnail-section__play-icon"></div>
                                    <div class="match-detail__thumbnail-section__play-desc">Goal Replay</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="match-detail__info-timeline 3 bg-theme" v-if="o.eventTypeKey === 'HT_LABEL' || o.eventTypeKey === 'FT_LABEL' || o.eventTypeKey === 'PEN_LABEL' || o.eventTypeKey === 'AET_LABEL' ">

                        <div class="match-detail__info-timeline__left display-flex-fe"> </div>
                        <div class="match-detail__info-timeline__middle ">  
                            <div class="match-detail__info-timeline__middle-time" v-if="o.eventTypeKey==='HT_LABEL'">{{$t('HALF_TIME')}}</div>
                            <div class="match-detail__info-timeline__middle-time" v-if="o.eventTypeKey==='FT_LABEL'">{{$t('FULL_TIME')}}</div>
                            <div class="match-detail__info-timeline__middle-time" v-if="o.eventTypeKey==='PEN_LABEL'">{{$t('PEN_SHOOT_OFF')}}</div>
                             <div class="match-detail__info-timeline__middle-time" v-if="o.eventTypeKey==='AET_LABEL'">{{$t('EXTRA_TIME')}}</div>
                                <div class="match-detail__info-timeline__middle-score">   
                                    <div>{{o.homeScore}}</div>
                                    <div>-</div>
                                    <div>{{o.awayScore}}</div>
                                </div>
                        </div>
                        <div class="match-detail__info-timeline__right"></div>
                    </div>               
                </div>
            </div>
            <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.event"></GeneralContentSkeleton> -->
            <!--  && !isSkeletonLoading.info -->
            <div v-if="matchDetailInfo.matchDate !== null && matchDetailInfo.matchDate !==''">
                <div class="match-detail__info-footer">{{$t("MATCH_INFO")}}</div>
                <div class="match-detail__info-footer__card" v-if="matchDetailInfo.matchDate !== null && matchDetailInfo.matchDate !==''">
                    <div class="match-detail__info-footer__card-title">{{$t("DATE")}}</div>
                    <div class="match-detail__info-footer__card-content">
                        <span>{{ dataFitlerMatchDate(matchDetailInfo.matchDate)}}</span>
                        <!-- <span v-if="currentLocale === 'vn'">{{$t(matchDetailInfo.matchDateDay)}}{{matchDetailInfo.vnMatchDate}}</span>
                        <span v-if="currentLocale === 'th'">{{matchDetailInfo.thMatchDate}}</span>                    
                        <span v-if="currentLocale === 'en'">{{matchDetailInfo.matchDate}}</span> -->
                    </div>
                </div>
                <div class="match-detail__info-footer__card"  v-if="matchDetailInfo.referee !== null && matchDetailInfo.referee !==''">
                    <div class="match-detail__info-footer__card-title">{{$t("REFEREE")}}</div>
                    <div class="match-detail__info-footer__card-content">{{matchDetailInfo.referee}}</div>
                </div>
                <div class="match-detail__info-footer__card"  v-if="matchDetailInfo.stadium !== null && matchDetailInfo.stadium !== '' ">
                    <div class="match-detail__info-footer__card-title">{{$t("VENUE")}}</div>
                    <div class="match-detail__info-footer__card-content">{{matchDetailInfo.stadium}}</div>
                </div>
                 <div class="match-detail__info-footer__card"  v-if="matchDetailInfo.leagueName !== null && matchDetailInfo.leagueName !==''">
                    <div class="match-detail__info-footer__card-title">{{$t("LEAGUE")}}</div>
                    <div class="match-detail__info-footer__card-content">{{matchDetailInfo.leagueName}}</div>
                </div>
            </div>
            <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.info"></GeneralContentSkeleton> -->
        </div>  
        <PopUpVideo ref="popUpVideoId" :currentVideoUrl="currentVideoUrl"></PopUpVideo>             
    </div>
       
</template>

<script>
import PopUpVideo from '@/components/matchDetail/PopUpVideo.vue'
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
import {mapGetters,mapMutations,mapActions} from 'vuex'
import config from '@/js/config.js'

export default {
    components : {
        PopUpVideo,
        GeneralContentSkeleton,
    },
    watch: {
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              this.getMatchDetailInfoData();
            }
        },
        manualRefreshMatchDetail: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getMatchDetailInfoData();
                    this.getMatchDetailEventData();
                }
            }
        },
    },
    data() {
        return {
            isShowEvent: false,
             matchTimelineList:[
            {
                position:1,
                isShowVideo:true,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
            {
                position:2,
                isShowVideo:false,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
            {
                position:3,
                isShowVideo:true,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
            {
                position:1,
                isShowVideo:false,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },
                {
                position:3,
                isShowVideo:false,
                name:'NiaNia',
                time:'3',
                score1:1,
                score2:2,
            },

            ],
            matchDetailInfo:{
                matchDateDay: ''
            },
            matchDetailEventList:[],
            matchId:'',
            eventInterval: null,
            currentVideoUrl :'',
            isSkeletonLoading: {
                info: false,
                event: false,
            },
            cnLocaleList: config.cnLocaleList,
        }
    },
    beforeUnmount() {
        clearInterval(this.eventInterval)
    },
    mounted() {
        this.getMatchDetailInfoData();
        this.getMatchDetailEventDataIntervel();
    },
    created() {
         this.matchId = this.$route.params.matchId
    },
    computed: {
        ...mapGetters([
            'isStopTimmer',
            'currentLocale',
            'manualRefreshMatchDetail',
        ]),
    },

    methods: {
        ...mapMutations([
            "MANUAL_REFRESH_MATCH_DETAIL",
        ]),
        ...mapActions([
            "getMatchDetaiInfo",
            "getMatchDetaiEvent"
        ]),
        async getMatchDetailInfoData(){
            let params = {
                matchId: this.matchId,
                timeZone: this.$tools.getCurrentTimeZone()
            }

            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.info = true;
            }
            const result = await this.getMatchDetaiInfo(params)
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.info = false;
                this.verifyIfAllAPICallsDone();
            }
            
            let matchDate = new Date(result.result.matchDate);
            let FormatedMatchDate = `, ${matchDate.getDate()} tháng ${matchDate.getMonth() + 1} năm ${matchDate.getFullYear()}`
            this.matchDetailInfo = result.result;
            // this.matchDetailInfo.vnMatchDate = FormatedMatchDate;
            // this.matchDetailInfo.thMatchDate = `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, ${matchDate.getDate()} ${this.$i18n.t(this.$tools.getMonth(matchDate.getMonth()))} ${matchDate.getFullYear()}`;
            // this.matchDetailInfo.matchDate = result.result.matchDate;

            //this.matchDetailInfo.matchDateDay = this.$tools.getFullDay(matchDate.getDay());
        }, 
        dataFitlerMatchDate(data) {
            let result;
            if (data) { 
                let matchDate = new Date(data);

                result = `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, 
                          ${matchDate.getDate()} 
                          ${this.$i18n.t(this.$tools.getMonth(matchDate.getMonth()))} 
                          ${matchDate.getFullYear()}
                         `;

                if (this.currentLocale === 'vn') {
                    result = `${this.$i18n.t(this.$tools.getFullDay(matchDate.getDay()))}, ${matchDate.getDate()} tháng ${matchDate.getMonth() + 1} năm ${matchDate.getFullYear()}`;
                } else if (this.cnLocaleList.includes(this.currentLocale)) {
                    result = `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, ${matchDate.getDate()}日 ${matchDate.getMonth() + 1}月 ${matchDate.getFullYear()}年`;
                }

                return result;  
            } else {
                return ''
            }

        },

        async getMatchDetailEventDataIntervel(){
            this.getMatchDetailEventData();

            this.eventInterval = setInterval(() => {
                if (this.isStopTimmer) {
                    clearInterval(this.eventInterval);
                } else {
                    this.getMatchDetailEventData();
                }            
            }, 10000);        
        },

        async getMatchDetailEventData(){
            let params ={
                matchId : this.matchId
            }

            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.event = true;
            }
            const result = await this.getMatchDetaiEvent(params);
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.event = false;
                this.verifyIfAllAPICallsDone();
            }

            if (result.result === null) { 
                 if (this.isStopTimmer) {
                      clearInterval(this.eventInterval);
                 }
                this.isShowEvent = false;
            } else {
                this.isShowEvent = true; 
                this.matchDetailEventList = result.result;

                for (let i=0; i<this.matchDetailEventList.length; i++) {
                    if (/\.(mp3|mp4)$/i.test(this.matchDetailEventList[i].videoUrl)) {
                         this.matchDetailEventList[i].isAudioType = true;
                    } else {
                         this.matchDetailEventList[i].isAudioType = false; 
                    }               
                }
            }
        },
        handleVideo(o){            
            this.currentVideoUrl = o.videoUrl;
            //this.currentVideoUrl = "https://www.youtube.com/embed/iMkhbiO7s9c"; //test video
            this.$refs.popUpVideoId.openCurrentVideo();
        },

        verifyIfAllAPICallsDone() {
            if (!this.isSkeletonLoading.info && !this.isSkeletonLoading.event) {
                this.MANUAL_REFRESH_MATCH_DETAIL();
            }
        },
    },
}
</script>

<style scoped>
.match-detail__info-header{
    padding: 0 1rem 1.82rem 1rem; 
    text-align: left;
    color: var(--color-grey-light);
    font-size: 0.8rem;
}
.match-detail__info-timeline{
    display:flex;
    justify-content: center;
    align-items: center;     
    padding:.75rem .8rem .75rem .8rem;
    border-bottom: 1px solid #212B30;
    font-size: .7rem;
}
.match-detail__info-timeline__left{
    flex:1.5
}
.match-detail__info-timeline__left-name{
    text-align: right;
    margin-right: .3rem;
}
.match-detail__info-timeline__left-name__1{
    font-size: .7rem;
}
.match-detail__info-timeline__left-name__2{
    font-size: .7rem;
    color: var(--color-theme-2);
}
.match-detail__info-replay{
    margin-top: .3rem;
    height: 8rem;
    cursor:pointer;
}
.match-detail__info-timeline__yellow{
    width: .5rem;
    height: .8rem;
    border-radius: .1rem;
    background-color: #FFE600;
    
}
.match-detail__info-timeline__yellow.left{
    margin-left: .4rem;
}
.match-detail__info-timeline__yellow.right{
    margin-right: .4rem;
}
.match-detail__info-replay{
    /* padding-top: 50%; */
}
.match-detail__info-timeline__middle{
    flex:.6;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.match-detail__info-timeline__middle-time{
    color: var(--color-theme-2);
    margin-bottom: .3rem;
    width: 6rem;
}
.match-detail__info-timeline__middle-score{
    display: flex;
}
.match-detail__info-timeline__middle-score>div{
    margin: 0 .3rem;
}
.match-detail__info-timeline__right{
    flex:1.5
}
.match-detail__info-timeline__right-name{
    margin-left: .3rem;
    margin-bottom: .3rem;
}
.match-detail__info-footer{
    padding: 1.82rem 1rem 1.82rem 1rem;
    text-align: left;
    color: var(--color-grey-light);
    font-size: 0.8rem;
}
.match-detail__info-footer__card{
    padding: 1rem 1rem .8rem 0;
    margin-left: 1rem;
    border-bottom: .1rem solid #212B30;
}
.match-detail__info-footer__card-title{
    font-size:.7rem;
    color: var(--color-grey-light);
    margin-bottom: .1rem;
}
.match-detail__info-footer__card-content{
    font-size:.88rem
}
.match-detail__thumbnail-iframe{
    height: 100%;
    padding:0;
    width: 100%;
    border:none;
}
@media (max-width: 768px){
    .match-detail__info-replay{
        height: 5rem;
    }
}
@media (min-width: 1000px) {
  .match-detail__info-timeline__middle {
    flex: 5.6 !important;
  }
}
</style>